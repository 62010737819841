import { CHANGE_SELECTED_SCHOOL } from "../constants/action-types";
import { CHANGE_SELECTED_CLASS } from "../constants/action-types";
import { CHANGE_PROF } from "../constants/action-types";
import { GET_KIDS_NUMBER } from "../constants/action-types";

const initialState = {
    selectedSchool: [],
    selectedClass: [],
    kids: ""
}

function schools_reducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SELECTED_SCHOOL:
            return {
                ...state,
                selectedSchool: action.school
            }
        case CHANGE_SELECTED_CLASS:
            return {
                ...state,
                selectedClass: action.class
            }
        case GET_KIDS_NUMBER:
            return {
                ...state,
                kids: action.kids
            }
        default:
            return state
    }
}

export default schools_reducer;