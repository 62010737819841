import { GET_SCHOOL_DB } from "../constants/action-types";
import { GET_CLASS_DB } from "../constants/action-types";

const initialState = {
    optionsSchule: [{ value: 'loading...', label: 'loading...' }],
    optionsKlasse: [
        { value: '1', label: '1A' },
        { value: '2', label: '1B' },
        { value: '3', label: '1C' },
        { value: '4', label: '1D' },
        { value: '5', label: '2A' },
        { value: '6', label: '2B' },
        { value: '7', label: '2C' },
        { value: '8', label: '2D' },
        { value: '9', label: '3A' },
        { value: '10', label: '3B' },
        { value: '11', label: '3C' },
        { value: '12', label: '3D' },
        { value: '13', label: '4A' },
        { value: '14', label: '4B' },
        { value: '15', label: '4C' },
        { value: '16', label: '4D' },
    ],
    optionsKlasse_zweite: [
        { value: '17', label: 'L1' },
        { value: '18', label: 'L2' },
        { value: '19', label: 'L3' },
        { value: '20', label: 'L4' },
        { value: '21', label: 'L5' },
        { value: '22', label: '3/4a' },
        { value: '23', label: '3/4b' },
        { value: '24', label: '3/4c' },
        { value: '25', label: '3/4d' },
        { value: '26', label: '3/4e' },
    ]

}

function get_options_daten(state = initialState, action) {
    switch (action.type) {
        case GET_SCHOOL_DB:
            return {
                ...state,
                optionsSchule: action.schools
            }
        case GET_CLASS_DB:
            return {
                ...state
            }
        default:
            return state
    }
}

export default get_options_daten;


