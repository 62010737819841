import React, { Component } from "react";
import Header from "./layouts/Header";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Hilfe from "./layouts/elements/Hilfe";

class AfterLogin extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { students } = this.props;
    let wuenschen =
      students.length > 0 && this.props.location.pathname === "/wuenschen"
        ? "min"
        : "";
    // Dritte Phase
    let thankyou = this.props.location.pathname === "/" ? "max" : "";
    // Second Phase
    // let thankyou = this.props.location.pathname === '/thankyou' ? 'max' : ''

    return (
      <div id="main-daten" className={wuenschen + " " + thankyou}>
        <Header />
        {/* <Hilfe /> */}
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    students: state.students.students,
  };
};

export default connect(mapStateToProps, null)(withRouter(AfterLogin));
