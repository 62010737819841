import React, { Component } from "react";

import { Link } from "react-router-dom";
import Weihnacht from "../assets/PNG/Image/Container.png";
import RosaBG from "../assets/PNG/Background/RosaBig.png";
import GreenBG from "../assets/PNG/Background/Green-Small.png";

import { ReactComponent as Button } from "../assets/SVG/CTA/Big.svg";

class Story extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div id="main-story" className="main-story">
        <div className="daten-head">
          <h1>Die Geschichte dahinter</h1>
          <h3>
            Seit fast 20 Jahren machen wir es uns in der Vorweihnachtszeit zur
            Aufgabe, benachteiligten Kindern an Düsseldorfer Schulen ein frohes
            Fest zu bescheren.
            <br></br>
            <br></br>
            Ursprünglich stand in jeder teilnehmenden Agentur ein Baum, an dem
            die Wunschzettel der Kinder hingen. Einfach einen Wunsch aussuchen,
            Geschenk besorgen und von den Organisator*innen an die Einrichtungen
            übergeben lassen.
          </h3>
        </div>
        <section className="story-section first-section">
          <div className="story-bild">
            <img className="story-bild-foto" src={Weihnacht} alt="" />
          </div>
          <div className="daten-head">
            <h1>Fortsetzung folgt … jetzt!</h1>
            <h3>
              Aufgrund der aktuellen Situation ist alles anders. Um den
              Infektions&shy;schutz&shy;maßnahmen gerecht zu werden, sind wir
              alle weniger in der Agentur und halten virtuell Kontakt. Deshalb
              haben wir uns entschlossen, auch die Weihnachtswunschaktion
              digital aufzusetzen und sie für unsere Kunden, Partner und Freunde
              zu öffnen. So können wir garantieren, dass zumindest eins gleich
              bleibt: Dass wir zu Weihnachten Kinderwünsche erfüllen.
            </h3>
          </div>
          <div className="background-section">
            <img src={RosaBG} />
          </div>
        </section>
        <section className="story-section second-section">
          {/* <div className="story-bild">
                        <img className="story-bild-foto" src={Weihnacht} alt=""/>
                    </div> */}
          <div className="daten-head">
            <h1>Die Schulen</h1>
            <h3>
              Seit 2005 arbeiten wir mit der Gemeinschaftsgrundschule
              Sonnenstraße im Düsseldorfer Stadtteil Garath zusammen. 2020 ist
              die Gemeinschaftsgrundschule Flurstraße in Düsseldorf-Flingern
              dazu gekommen. Hier lernen etwa 600 Kinder im Alter von 6 bis 11
              Jahren. Überdurchschnittlich viele von ihnen sind bedürftig,
              weshalb wir allen zu Weihnachten mit kleinen Geschenken eine
              individuelle Freude bereiten möchten.
            </h3>
          </div>
        </section>
        <section className="story-section third-section">
          <Link to="/start">
            <Button></Button>
          </Link>
          <div className="about-layout">
            <div id="about-layout-links">
              <Link to="/story">
                <h3>Mehr zur Aktion</h3>
              </Link>
              <a
                href="https://bbdo.de/de/imprint"
                target="_blank"
                rel="noreferrer"
              >
                <h3>Impressum</h3>
              </a>
              <Link to="/datenschutz">
                <h3>Datenschutz</h3>
              </Link>
            </div>
          </div>
          <div className="background-section">
            <img src={GreenBG} />
          </div>
        </section>
      </div>
    );
  }
}

export default Story;
