import { GET_DATENSCHUTZ, GET_TOKEN, CHECK_PASSWD, GET_STUDENTS, CHANGE_ACTIVE_STATUS, SELECT_STUDENT } from "../constants/action-types";

import axios from "axios"
import DOMPurify from 'dompurify';

export const checkPassword = (payload) => {
    return (dispatch, getState) => { 
        let password_to_check = atob(getState().check_passwd.password_to_check)
        let validate = false

        if (password_to_check === payload.pass) {
            validate = true
            
        }
        dispatch({
            type: CHECK_PASSWD,
            validate: validate
        })
    }
}

export const get_datenschutz = () => {
    return (dispatch, getState) => {
        // let url = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'))
        Promise.all([
            axios.get('https://weihnachtswuensche.charity/wp-json/wp/v2/pages?slug=datenschutzerklaerung'),
        ])
            .then(([datenschutz]) => { 
                let purified = DOMPurify.sanitize(datenschutz.data[0].content.rendered);
                dispatch({ type: GET_DATENSCHUTZ, datenschutz:  purified})
        })
    }
}

export const get_token = () => {
    return (dispatch, getState) => {
        const params = {
            username: "fernando",
            password: "11235814Bb."
        }
        var bodyFormData = new FormData();
        bodyFormData.append('username', 'fernando');
        bodyFormData.append('password', '11235813Bb.');
        axios.post("https://weihnachtswuensche.charity/wp-json/jwt-auth/v1/token", bodyFormData).then((response) => {
          
            dispatch({ type: GET_TOKEN, token: response.data.token})
        }).catch((err) => {
            // console.log(err)
        })
    }
}

export const get_students = (token) => {
    return (dispatch, getState) => {
         
        Promise.all([
            axios.get('https://weihnachtswuensche.charity/wp-json/bbdo-wunsche/v1/students', null, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }),
        ]).then(([students]) => { 
           

                dispatch({ type: GET_STUDENTS, students:  students.data})
        })
    }
}

export const change_active_status = (id, active, token) => {
    return (dispatch, getState) => {
        const params = {
            student_id: id,
            active: active
        }

        axios.post('https://weihnachtswuensche.charity/wp-json/bbdo-wunsche/v1/active', params, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then((status) => { 
                dispatch({ type: CHANGE_ACTIVE_STATUS })
               

                // dispatch({ type: CHANGE_ACTIVE_STATUS, students:  students.data})
        })
    }
}

export const change_bezahlt_status = (id, paid, token) => {
    return (dispatch, getState) => {
        const params = {
            student_id: id,
            paid: paid
        }

        axios.post('https://weihnachtswuensche.charity/wp-json/bbdo-wunsche/v1/paid', params, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then((status) => { 
                dispatch({ type: CHANGE_ACTIVE_STATUS })
              

                // dispatch({ type: CHANGE_ACTIVE_STATUS, students:  students.data})
        })
    }
}

export const select_student = (id, color) => {
    return (dispatch, getState) => {
        dispatch({
            type: SELECT_STUDENT,
            id: id,
            color: color
        })
        
    }
}