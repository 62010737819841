import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { change_bezahlt_status } from "../redux/actions/index";
import { ReactComponent as ButtonZu } from "../assets/SVG/CTA/Big-zu-imp.svg";
import GreenBG from "../assets/PNG/Background/Green-Small.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

import Button from "./layouts/elements/Button";

class Share extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.history.action !== "POP") {
      const { student, token, change_status } = this.props;

      change_status(student, "1", token);
    }
  }

  render() {
    // return (
    return this.props.history.action !== "POP" ? (
      <div id="main-share" className="main-share">
        <div className="daten-head">
          <h3>
            Vielen Dank für die Unterstützung! Die Geschenke sind schon auf dem
            Weg in unsere Geschenkeabteilung und werden vor Weihnachten
            übergeben.
          </h3>
        </div>
        <section className="share-go-back">
          <div className="daten-head">
            <h2>Noch einen Wunsch erfüllen</h2>
            <Link to="/wuenschen" className="link">
              <ButtonZu />
            </Link>
          </div>
          <div className="background-section">
            <img src={GreenBG} />
          </div>
        </section>
        <div className="daten-head">
          <h2>
            Jetzt auf Social Media teilen und andere zum Mitmachen bewegen!
          </h2>
        </div>
        <section id="share-buttons">
          <FacebookShareButton
            url={"https://weihnachtswuensche.charity/"}
            quote={
              "Jetzt unerfüllte Kinder-Weihnachtswünsche wahr werden lassen"
            }
          >
            <Button className="fb">Facebook</Button>
          </FacebookShareButton>
          <LinkedinShareButton
            url={"https://weihnachtswuensche.charity/"}
            title={
              "Jetzt unerfüllte Kinder-Weihnachtswünsche wahr werden lassen"
            }
            summary={
              "Gemeinsam erfüllen wir seit fast 20 Jahren Weihnachtswünsche benachteiligter Kinder, die sonst vielleicht leer ausgehen.  Jetzt mitmachen!"
            }
            source={"https://weihnachtswuensche.charity/"}
          >
            <Button className="linkedin">LinkedIn</Button>
          </LinkedinShareButton>
          <WhatsappShareButton
            url={"https://weihnachtswuensche.charity/"}
            title={
              "Jetzt unerfüllte Kinder-Weihnachtswünsche wahr werden lassen"
            }
            separator={" "}
          >
            <Button className="whats">Whatsapp</Button>
          </WhatsappShareButton>
        </section>
        <div id="about-layout-links">
          <Link to="/story">
            <h3>Mehr zur Aktion</h3>
          </Link>
          <a href="https://bbdo.de/de/imprint" target="_blank" rel="noreferrer">
            <h3>Impressum</h3>
          </a>
          <Link to="/datenschutz">
            <h3>Datenschutz</h3>
          </Link>
        </div>
      </div>
    ) : (
      <Redirect to="/wuenschen" />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    student: state.students.selected_student_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    change_status: (id, bezahlt, token) =>
      dispatch(change_bezahlt_status(id, bezahlt, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Share));
