import React, { Component } from "react"
import AboutLayout from "./layouts/AboutLayout";
import { Link } from "react-router-dom"

// !Assets
import { ReactComponent as Button } from "../assets/SVG/CTA/Big-los.svg"

class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div id="main-about">
                <AboutLayout />
                <div className="aboutCTA">
                    <Link to="/wuenschen">
                        <Button />
                    </Link>
                </div>
                <div className="about-layout">
                    <div  id="about-layout-links">
                        <Link to="/story"><h3>Mehr zur Aktion</h3></Link>
                        <a href="https://bbdo.de/de/imprint" target="_blank" rel="noreferrer"><h3>Impressum</h3></a>
                        <Link to="/datenschutz"><h3>Datenschutz</h3></Link>
                    </div>
                </div>
            </div>
                
        )
    }
}

export default About;