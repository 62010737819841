import { ReactComponent as SnowEnd } from "../../assets/SVG/Background/End.svg"
import Tree from "./elements/Tree"

// !Assets
// import Background from '../../assets/PNG/Background/Gradientv2.png';
import Background from '../../assets/SVG/Background/blue-gradient-bg-radial.svg';

const BG = () => {
    return (
        <div id="snow-end" style={{ backgroundImage: "url(" + Background + ")" }}>
            
        </div>
    )
}

export default BG;