// !Bücher
import Image1 from "../../assets/Geschenke/Buch/9783551160089.jpeg";
import Image2 from "../../assets/Geschenke/Buch/9783411722105.jpeg";
import Image3 from "../../assets/Geschenke/Buch/9781789417258.jpeg";
import Image4 from "../../assets/Geschenke/Buch/9783551190949.jpeg";
import Image5 from "../../assets/Geschenke/Buch/9783551182791.jpeg";
import Image6 from "../../assets/Geschenke/Buch/9783551088499.jpeg";
import Image7 from "../../assets/Geschenke/Buch/9783551089816.jpeg";
import Image8 from "../../assets/Geschenke/Buch/9783570177969.jpeg";
import Image9 from "../../assets/Geschenke/Buch/9783833241208.jpeg";
import Image10 from "../../assets/Geschenke/Buch/9783791375021.jpeg";

export const booksImages_or = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
];

export const books_or = [
  "Mein großer Freizeit-Rätselblock",
  "Mein großer Lesespaß mit Rabe Linus - 1. Klasse",
  "Mein Immer-wieder-Stickerbuch: Das Krippenspiel",
  "Mein Mega-Bastelblock Weihnachten",
  "Conni Gelbe Reihe (Beschäftigungsbuch): Mein kunterbunter Schulanfang",
  "Ich habe eine Freundin, die ist Notärztin",
  "Conni lernt backen",
  "Marvel 5-Minuten-Geschichten",
  "Jurassic World: Die Dinosaurier sind los!",
  "Wunder der Natur im Park",
];
