import React from 'react';
import { render } from "react-dom";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
// import { CSSTransition } from "react-transition-group";

// !Components
import App from './components/App';
import AfterLogin from './components/AfterLogin';
import About from './components/About';
import Story from './components/Story';
import Wuenschen from './components/Wuenschen';
import DetailWunsch from './components/DetailWunsch';
import Datenschutz from './components/Datenschutz';
import Overlay from './components/Overlay';
import Share from './components/Share';
import ThankYou from './components/ThankYou';

// !Layout ELements
import Particles from 'react-particles-js';
import Snow from "./components/layouts/Snow"
import BG from "./components/layouts/BG"

// !Redux
import { Provider } from "react-redux";
import store from "./redux/store/index";


import reportWebVitals from './reportWebVitals';
import "./general.scss";




function viewportUnits() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

// const routes = [
//   { path: '/', name: 'App', Component: App },
//   { path: '/*', name: 'App', Component: App },
//   { path: '/about', name: 'About', Component: About },
//   { path: '/daten', name: 'Daten', Component: Daten },
// ]

render(
  <Provider store={store}>
    {/* <BrowserRouter basename='/phase2/'> */}
    <BrowserRouter>
      <Particles
        className="snowing"
        params={store.getState().particles.params} />
      <BG />
      <div id="main">
        {viewportUnits()}
        <Switch>
          
          {/* Phase 1 & 2 */}
          <Route path="/datenschutz" exact >
            <AfterLogin> <Datenschutz /> </AfterLogin>
          </Route>
          <Route path="/overlay" exact >
            <AfterLogin> <Overlay /> </AfterLogin>
          </Route>
          {/* Dritte Phase */}
          <Route path='/' exact >
            <AfterLogin> <ThankYou /> </AfterLogin>
          </Route>

          {/* Zweite Phase */}
          
          <Route path='/start' exact >
            <AfterLogin> <About /> </AfterLogin>
          </Route>

          <Route path='/story' exact >
            <AfterLogin> <Story /> </AfterLogin>
          </Route>

          <Route path='/wuenschen' exact >
            <AfterLogin> <Wuenschen /> </AfterLogin>
          </Route>

          <Route path='/detail' exact >
            <AfterLogin> <DetailWunsch /> </AfterLogin>
          </Route>

          <Route path='/share' exact >
            <AfterLogin> <Share /> </AfterLogin>
          </Route>

          <Route path='/thankyou' exact >
            <AfterLogin> <ThankYou /> </AfterLogin>
          </Route>

          <Route path="/" component={App} />

        </Switch>
      </div>
      <Snow />
     </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
