const TextBox = (props) => {
    return (
        <div className={ ( typeof props.className !== 'undefined' ? props.className : "" ) + " textBox"}>
            <div className="text">
                {props.children}
            </div>
        </div>
    )
}

export default TextBox;