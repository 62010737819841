// import Logo from '../../assets/PNG/Logo.svg'
import { ReactComponent as Logo } from "../../assets/SVG/Logov2.svg"
import { ReactComponent as X } from "../../assets/SVG/Icons/22px/Close.svg"

import Button from "./elements/Button";
import { Link , withRouter} from "react-router-dom"

const Header = (props) => {
    return (
        <div id="header">       
            <Logo />
            {props.location.pathname !== '/thankyou' && props.location.pathname !== '/' &&
            (
                props.location.pathname !== '/datenschutz' ?
                <Button className="hilfe-cta">
                {
                    // props.location.pathname === '/overlay' ? <h3 className="x" onClick={props.history.goBack.bind(this)}>&times;</h3> : <Link to="/overlay"><h3>Hilfe</h3></Link>
                    props.location.pathname === '/overlay' ? <div className="x"><X  onClick={props.history.goBack.bind(this)}>&times;</X> </div>: <Link to="/overlay"><h3>Hilfe</h3></Link>
                }
                </Button>
                :
                <Button className="hilfe-cta"><div className="x"><X  onClick={props.history.goBack.bind(this)}>&times;</X></div></Button>
                // null
            )}
        </div>
    )
}

export default withRouter(Header);