import React, { Component } from 'react';
import Intro from './layouts/Intro';

class App extends Component {
  render() {
      return (
          <Intro />
    )
  }
}


export default App;
