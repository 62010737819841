import React, { Component } from "react"
import { Link } from "react-router-dom";
import { ReactComponent as Button } from "../../assets/SVG/CTA/Big-2.svg"

class Login extends Component {
    
    render() {
        return (
        // return (
            <div id="main-login">
                <Link to="/start">
                    <Button />          
                </Link>
                <div id="daten-head">
                    <Link to="/story">
                        <h3>Mehr erfahren</h3>
                    </Link>
                </div> 
            </div>
        )  
    }
}


export default Login; 