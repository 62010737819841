export const CHECK_PASSWD = "CHECK_PASSWD";
export const GET_DATENSCHUTZ = "GET_DATENSCHUTZ";
export const GET_SCHULEN = "GET_SCHULEN";
export const GET_KLASSEN = "GET_KLASSEN";
export const GET_SCHOOL_DB = "GET_SCHOOL_DB";
export const GET_CLASS_DB = "GET_SCHOOL_DB";
export const CHANGE_SELECTED_SCHOOL = "CHANGE_SELECTED_SCHOOL";
export const CHANGE_SELECTED_CLASS = "CHANGE_SELECTED_CLASS";
export const CHANGE_PROF = "CHANGE_PROF";
export const GET_KIDS_NUMBER = "GET_KIDS_NUMBER";
export const GET_TOKEN = "GET_TOKEN";
export const GET_STUDENTS = "GET_STUDENTS";
export const CHANGE_ACTIVE_STATUS = "CHANGE_ACTIVE_STATUS";
export const SELECT_STUDENT = "SELECT_STUDENT";