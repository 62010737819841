import { createStore } from "redux";
import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// !Reducers
import particles from "../reducers/particles";
import check_passwd from "../reducers/passwd";
import get_datenschutz from "../reducers/datenschutz";
import get_options_daten from "../reducers/selectOptions";
import schools_reducer from "../reducers/schools";
import token from "../reducers/token";
import students from "../reducers/students";

const rootReducer = combineReducers({
    particles: particles,
    check_passwd: check_passwd,
    datenschutz: get_datenschutz,
    schulen_options: get_options_daten,
    selected_options: schools_reducer,
    token: token,
    students: students
}) 

//  !Store
const store = createStore(rootReducer,  applyMiddleware(thunk));

export default store;