import { GET_DATENSCHUTZ } from "../constants/action-types";

const initialState = {
    datenschutz_content: ""
}

function get_datenschutz(state = initialState, action) {
    switch (action.type) {
        case GET_DATENSCHUTZ:
            return { datenschutz_content: action.datenschutz }
        default:
            return state;
    }
}

export default get_datenschutz;