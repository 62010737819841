import React, { Component } from "react";
import { Redirect, withRouter, Link } from "react-router-dom";
import { change_active_status } from "../redux/actions/index";
import { connect } from "react-redux";
import Stripe from "./Stripe";
import PaypalButton from "./PaypalButton";

import { ReactComponent as Arrow } from "../assets/SVG/Icon/right-arrow.svg";
import { booksImages_or, books_or } from "./helpers/books";
import { games, gamesImages } from "./helpers/games";
import { sos, sosImages } from "./helpers/sos";
import BackgroundImage from "../assets/background.jpg";
class DetailWunsch extends Component {
  componentDidMount() {
    // const { student } = this.props
    const { student_id, token, change_status } = this.props;

    change_status(student_id, "1", token);

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { student_id, token, change_status } = this.props;

    change_status(student_id, "0", token);
  }

  onClickGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { student, bgColor } = this.props;

    let filteredBookIndex = "";
    let filteredToyIndex = "";

    let all = [];
    let allImages = [];
    all.push(...books_or, ...games, ...sos);
    allImages.push(...booksImages_or, ...gamesImages, ...sosImages);
    if (student) {
      filteredBookIndex = all.indexOf(student.buch);
      filteredToyIndex = all.indexOf(student.spiel);
    }

    return student ? (
      <div id="main-single-student" className="main-single-student">
        {/* <div className="single" style={{ "backgroundColor": bgColor }}>
                    <div className="single-name">
                        <h2>{ student.student}</h2>
                        <p>{ "Klasse " + student.klasse}</p>
                    </div>
                    
                </div> */}
        <section className="wuenschen-list">
          <div
            className="wuensch-container"
            style={{ backgroundColor: bgColor }}
          >
            <div className="pfeile">
              <Arrow onClick={this.onClickGoBack} />
            </div>
            <div className="wuensch-name">
              {/* <h2>{student.student}</h2> */}
              <h2>
                {student.student} <br></br>wünscht sich …
              </h2>
              {/* {student.schule == "GGS Flurstraße" || student.schule == "GGS Sonnenstrasse"?<p>{"Klasse " + student.klasse}</p>:<p>{"Wohngruppe/ Kinderdorffamilie " + student.klasse}</p>} */}
            </div>
          </div>
          <div
            className="rest-single-content"
            style={{ backgroundColor: bgColor }}
          >
            <div className="trennen"></div>
            <div className="bildBox">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={BackgroundImage} alt="" />
                <img
                  src={allImages[filteredBookIndex]}
                  className="secondImg"
                  style={{ position: "absolute" }}
                  alt=""
                />
              </div>
              <p>{student.buch}</p>
            </div>
            <div className="bildBox">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={BackgroundImage} alt="" />
                <img
                  src={allImages[filteredToyIndex]}
                  alt=""
                  className="secondImg"
                  style={{ position: "absolute" }}
                />
              </div>
              <p>{student.spiel}</p>
            </div>
            <div className="head">
              <h2>
                Für 20 Euro kann der Kinderwunsch erfüllt werden. Jetzt
                mitmachen und Bezahl&shy;methode auswählen!{" "}
              </h2>
            </div>
            <div className="trennen"></div>
            <div className="head head-flex">
              <h2>Betrag</h2>
              <h2>20 €</h2>
            </div>
            <div className="head">
              <p>
                Bitte beachten Sie, dass bei PayPal, Lastschrift und Sofort eine
                Lieferadresse abgefragt wird. Diese können sie jedoch ignorieren
                bzw. frei wählen, da die Geschenke direkt von uns an die Kinder
                gesendet werden.
              </p>
            </div>
            <PaypalButton />
            <Stripe />
            <div id="about-layout-links">
              <Link to="/story">
                <h3>Mehr zur Aktion</h3>
              </Link>
              <a
                href="https://bbdo.de/de/imprint"
                target="_blank"
                rel="noreferrer"
              >
                <h3>Impressum</h3>
              </a>
              <Link to="/datenschutz">
                <h3>Datenschutz</h3>
              </Link>
            </div>
          </div>
        </section>
      </div>
    ) : (
      <Redirect to="/wuenschen"></Redirect>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    student: state.students.students
      .filter((student) => student.id === state.students.selected_student_id)
      .shift(),
    student_id: state.students.selected_student_id,
    bgColor: state.students.selected_color,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change_status: (id, active, token) =>
      dispatch(change_active_status(id, active, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailWunsch));
