import { withRouter } from "react-router-dom";

import { ReactComponent as TreeSVG } from "../../../assets/SVG/Illustration/treev2.svg";
const Tree = (props) => {
  // Dritte Phase
  return null;
  return props.location.pathname !== "/" &&
    props.location.pathname !== "/story" &&
    props.location.pathname !== "/wuenschen" ? null : (
    <div className="tree">
      <TreeSVG />
    </div>
  );
};

export default withRouter(Tree);
