import React, {useState, useEffect} from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import PayButtons from './PayButtons';
import { withRouter } from "react-router-dom";
import $ from 'jquery';
import axios from "axios"
import { connect } from "react-redux";

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        $('#root').css({'opacity':0.5})
        let clientSecret = ""
        event.preventDefault();

        axios.get("https://weihnachtswuensche.charity/wp-json/bbdo-wunsche/v1/secret", null, {
            headers: {
                'Authorization': 'Bearer ' + props.token,
            }
        }).then((response) => {
            return JSON.parse(response.data);
        }).then(async function(responseJson){
            clientSecret = responseJson.client_secret;
    
            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }
    
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                }
            });
    
            if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                $('#root').css({'opacity':''})
                alert(result.error.message);
            } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    $('#root').css({'opacity':''})
                    props.history.push('/share')
                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                }
            }
        }).catch((err) => {
            // console.log(err)
        })
        
    };

  
    
    return (
        <div className="stripe-container">
            <PayButtons stripe={ stripe } />
            <form onSubmit={handleSubmit}>
                <CardSection />
                <button disabled={!stripe}>Zahlung bestätigen</button>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.token.token
    }
}
export default connect(mapStateToProps, null)(withRouter(CheckoutForm));