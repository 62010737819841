import React, {useState, useEffect} from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Redirect, withRouter } from "react-router-dom";
import axios from "axios"
import { connect } from "react-redux";
import $ from 'jquery';

const PayButtons = (props) => {
    const [paymentRequest, setPaymentRequest] = useState(null);
    const style = {
        paymentRequestButton: {
            height: '55px'
        }
        
    }
        
    useEffect(() => {
        if (props.stripe) {
            const pr = props.stripe.paymentRequest({
                country: 'DE',
                currency: 'eur',
                total: {
                    label: 'Weihnachtswünsche',
                    amount: 2000,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });
        }
    }, [props.stripe]);

    
    if (paymentRequest) {
        paymentRequest.on('paymentmethod', async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
            $('#root').css({'opacity':0.5})
            let clientSecret = ""
            const {stripe} = props
            axios.get("https://weihnachtswuensche.charity/wp-json/bbdo-wunsche/v1/secret", null, {
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                }
            }).then((response) => {
                return JSON.parse(response.data);
            }).then(async function(responseJson){
                clientSecret = responseJson.client_secret;
                
                const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
                    clientSecret,
                    {payment_method: ev.paymentMethod.id},
                    {handleActions: false}
                );
        
                if (confirmError) {
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    ev.complete('fail');
                    console.log(confirmError);
                    alert("Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.")
                    $('#root').css({'opacity':''})
                } else {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    // Check if the PaymentIntent requires any actions and if so let Stripe.js
                    // handle the flow. If using an API version older than "2019-02-11" instead
                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                    if (paymentIntent.status === "requires_action") {
                        // Let Stripe.js handle the rest of the payment flow.
                        const {error} = await stripe.confirmCardPayment(clientSecret);
                        if (error) {
                            // The payment failed -- ask your customer for a new payment method.
                            alert("Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.")
                            $('#root').css({'opacity':''})
                        } else {
                            // The payment has succeeded.
                            $('#root').css({ 'opacity': '' })
                            props.history.push('/share')
                            // return <Redirect to="/share" />
                        }
                    } else {
                        // The payment has succeeded.
                        $('#root').css({ 'opacity': '' })
                        props.history.push('/share')
                        // return <Redirect to="/share" />
                    }
                }
            }).catch((err) => {
                // console.log(err)
            })
        });
        return <PaymentRequestButtonElement options={{paymentRequest, style}} />
    }

    // Use a traditional checkout form.
    return '';

};
const mapStateToProps = (state) => {
    return {
        token: state.token.token
    }
}
export default connect(mapStateToProps, null)(withRouter(PayButtons));