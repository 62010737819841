import AboutLayout from "./layouts/AboutLayout"
import { Link, withRouter } from "react-router-dom";

const Overlay = (props) => {
    return (
        <AboutLayout className='paddingOverlay'> 
            <div id="about-layout-links">
                <Link to="/story"><h3>Mehr zur Aktion</h3></Link>
                <a href="https://bbdo.de/de/imprint" target="_blank" rel="noreferrer"><h3>Impressum</h3></a>
                <Link to="/datenschutz"><h3>Datenschutz</h3></Link>
            </div>
        </AboutLayout>
    )
}

export default withRouter(Overlay)