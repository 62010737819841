// !Spielzeuge
import Image1 from '../../assets/Geschenke/SOS/4014489126447.jpg';
import Image2 from '../../assets/Geschenke/SOS/9781782327790.jpg';
import Image3 from '../../assets/Geschenke/SOS/9783551036100.jpg';
import Image4 from '../../assets/Geschenke/SOS/9783551250032.jpg';
import Image5 from '../../assets/Geschenke/SOS/9783551250414.jpg';
import Image6 from '../../assets/Geschenke/SOS/9783551317322.jpg';
import Image7 from '../../assets/Geschenke/SOS/9783551319708.jpg';
import Image8 from '../../assets/Geschenke/SOS/9783551320063.jpg';
import Image9 from '../../assets/Geschenke/SOS/9783551650702.jpg';
import Image10 from '../../assets/Geschenke/SOS/9783551652713.jpg';
import Image11 from '../../assets/Geschenke/SOS/9783570212141.jpg';
import Image12 from '../../assets/Geschenke/SOS/9783737358842.jpg';
import Image13 from '../../assets/Geschenke/SOS/9783751200417.jpg';
import Image14 from '../../assets/Geschenke/SOS/9783772443954.jpg';
import Image15 from '../../assets/Geschenke/SOS/9783791504544.jpg';
import Image16 from '../../assets/Geschenke/SOS/9783836941365.jpg';
import Image17 from '../../assets/Geschenke/SOS/9783845835921.jpg';
import Image18 from '../../assets/Geschenke/SOS/9783949465000.jpg';
import Image19 from '../../assets/Geschenke/SOS/9783551036094.jpg';
import Image20 from '../../assets/Geschenke/SOS/9783551051455.jpg';
import Image21 from '../../assets/Geschenke/SOS/9783551051691.jpg';
import Image22 from '../../assets/Geschenke/SOS/9783458179658.jpg';
import Image23 from '../../assets/Geschenke/SOS/9783458179672.jpg';
import Image24 from '../../assets/Geschenke/SOS/9783845839998.jpg';

export const sosImages = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14,
    Image15,
    Image16,
    Image17,
    Image18,
    Image19,
    Image20,
    Image21,
    Image22,
    Image23,
    Image24,
];

export const sos = [
    "Meine Freunde - Meerjungfrau (mit Wendepailletten)",
    "Meine liebsten Wisch-und-weg-Bücher zum Schulstart",
    "Unkaputtbar: Erstes Wissen: Große Tiere",
    "Hör mal (Soundbuch): Der Bauernhof",
    "Hör mal (Soundbuch): Die Fahrzeuge",
    "Wir Kinder vom Bahnhof Zoo",
    "Disney - Villains 1: Die Schönste im ganzen Land",
    "Bitte nicht öffnen: Doppelband (Enthält die Bände 1: Bissig!, 2: Schleimig!)",
    "Freddy und Flo gruseln sich vor gar nix!",
    "Die Schule der magischen Tiere",
    "Die Sonne im Gesicht",
    "Game Changer",
    "Die Olchis retten das Dino-Baby",
    "Landkartenrätselbuch für Kinder",
    "Drachenreiter",
    "Die kleine Raupe Nimmersatt",
    "Lasse in der 1. Klasse (Lasse )",
    "Vergiss mein nicht",
    "Unkaputtbar: Erstes Wissen: Bei der Feuerwehr",
    "Baby Pixi 43: Mein Baby-Pixi Buggybuch: Fahrzeuge" ,
    "Baby Pixi 67: Mein Baby-Pixi-Buggybuch: Meine ersten Sachen" ,
    "Marie Curie" ,
    "Stephen Hawking" ,
    "Erstes Stickern - Winterzeit",
]