
import React, {Component} from "react";
import ReactDOM from "react-dom";
import { Redirect } from "react-router-dom" ;
import scriptLoader from "react-async-script-loader";
import $ from 'jquery'

 const CLIENT = {
   sandbox: "AViUtZtbLX8RMuevpAl3bVcqdlVgsbZ3UPEPsKiv36ToTANhmby1alSnpkt_qTi3SsHJxZ5XA08IhiMS",
   production: "AViUtZtbLX8RMuevpAl3bVcqdlVgsbZ3UPEPsKiv36ToTANhmby1alSnpkt_qTi3SsHJxZ5XA08IhiMS"
//    sandbox: "AfbdKJQVYNGKuL74URi3jzO8WQ_JS_xbV6z_peBZsnkajL8SpJfxoKNbIW6XtwHJX1-0AaERe_g_glem",
//    production: "AfbdKJQVYNGKuL74URi3jzO8WQ_JS_xbV6z_peBZsnkajL8SpJfxoKNbIW6XtwHJX1-0AaERe_g_glem"
 };

 const CLIENT_ID =
   process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;


let PayPalButton = null;

class PaypalButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showButtons: false,
            paid:false
        }
        window.React = React;
        window.ReactDOM = ReactDOM;
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {
            PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
            this.setState({ loading: false, showButtons: true });
        }
    }
    shouldComponentUpdate(nextProps) {
        
        const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

        const scriptJustLoaded = !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
            if (isScriptLoadSucceed) {
                PayPalButton = window.paypal.Buttons.driver("react", {
                React,
                ReactDOM
                });
                this.setState({ loading: false, showButtons: true });
            }
        }

        return true
    }

    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [
            {
                amount: {
                    value: "20",
                },
            },
            ],
        });
    }

    onApprove(data, actions) {
        $('#root').css({'opacity':0.5})
        actions.order.capture().then(details => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID
            };
            $('#root').css({'opacity':''})
            this.setState({ showButtons: false, paid: true });
        });
    }

    render() {
        const { showButtons, loading, paid } = this.state;
        const styled = {
            height: 55,
            color: "white"
        }
        return (
            <div className="paypalButtons">
                {loading && <h1>Loading Paypal...</h1>}
                {showButtons && (
                    <PayPalButton
                    createOrder={(data, actions) => this.createOrder(data, actions)}
                    onApprove={(data, actions) => this.onApprove(data, actions)}
                    style= {styled}
                    />
                )}

                {paid && <Redirect to="/share" />}  
            </div>
        )
    }
}


export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR&locale=de_DE&disable-funding=credit,card,giropay,ideal,mybank,mercadopago,p24,venmo,bancontact,blik,eps`)(PaypalButton);