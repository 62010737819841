import React, { Component } from "react";
import TextBox from "./elements/TextBox";
import { Link } from "react-router-dom";

// !Assets
import { ReactComponent as Baum } from "../../assets/SVG/Icon/Tree.svg"
import { ReactComponent as Gift } from "../../assets/SVG/Icon/Gift.svg"
import { ReactComponent as ChristmasBall } from "../../assets/SVG/Icon/ChristmasBall.svg"
class AboutLayout extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div id="about-layout" className={typeof this.props.className !== 'undefined'? this.props.className:''}>
                <div className="about-head">
                    <h2>So funktioniert's:</h2>
                </div>
                <TextBox className="withSvg">
                    <Baum />
                    <h3>Schritt 1: Kind auswählen, dessen Wunsch erfüllt werden soll.</h3>
                </TextBox>
                <TextBox className="withSvg">
                    <Gift />
                    <h3>Schritt 2: PayPal (Lastschrift, Sofort, Apple Pay, Google Pay) oder Kreditkarte? Einfach Bezahl&shy;methode auswählen.</h3>
                </TextBox>
                <TextBox className="withSvg">
                    <ChristmasBall />
                    <h3>Schritt 3: Zahlung abschließen und fertig! Wir sorgen dafür, dass alle Geschenke pünktlich vor Weihnachten ankommen und Freude bereiten.
                    </h3>
                </TextBox>
                {this.props.children}
            </div>
        )
    }
}

export default AboutLayout;