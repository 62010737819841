// !Spielzeuge
import Image1 from "../../assets/Geschenke/Spielzeug/4260370820974.jpeg";
import Image2 from "../../assets/Geschenke/Spielzeug/4260470080025.jpeg";
import Image3 from "../../assets/Geschenke/Spielzeug/4260470080001.jpeg";
import Image4 from "../../assets/Geschenke/Spielzeug/4033477903204.jpeg";
import Image5 from "../../assets/Geschenke/Spielzeug/4895205602700.jpeg";
import Image6 from "../../assets/Geschenke/Spielzeug/5010993554850.jpeg";
import Image7 from "../../assets/Geschenke/Spielzeug/4007396022049.jpeg";
import Image8 from "../../assets/Geschenke/Spielzeug/4007396037807.jpeg";
import Image9 from "../../assets/Geschenke/Spielzeug/4007396016581.jpeg";
import Image10 from "../../assets/Geschenke/Spielzeug/4007396029208.jpeg";
import Image11 from "../../assets/Geschenke/Spielzeug/4007396009033.jpeg";
import Image12 from "../../assets/Geschenke/Spielzeug/4002051631352.jpeg";
import Image13 from "../../assets/Geschenke/Spielzeug/3558380006770.jpeg";
import Image14 from "../../assets/Geschenke/Spielzeug/4002051601966.jpeg";
import Image15 from "../../assets/Geschenke/Spielzeug/4007396049909.jpeg";
import Image16 from "../../assets/Geschenke/Spielzeug/3665361035114.jpeg";
import Image17 from "../../assets/Geschenke/Spielzeug/4005556234639.jpeg";
import Image18 from "../../assets/Geschenke/Spielzeug/4007396077902.jpeg";
import Image19 from "../../assets/Geschenke/Spielzeug/4007396049008.jpeg";
import Image20 from "../../assets/Geschenke/Spielzeug/4015566603998.jpeg";
import Image21 from "../../assets/Geschenke/Spielzeug/4015566601871.jpeg";
import Image22 from "../../assets/Geschenke/Spielzeug/4015682252001.jpeg";
import Image23 from "../../assets/Geschenke/Spielzeug/4015682298009.jpeg";
import Image24 from "../../assets/Geschenke/Spielzeug/4270003101202.jpeg";

export const gamesImages = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  Image21,
  Image22,
  Image23,
  Image24,
];

export const games = [
  "Der Fuchs im Wald",
  "SKYJO ACTION",
  "Skyjo",
  "Pocket Games Kidz",
  "HP Socken Gryffindor 3er",
  "Monopoly Deal",
  "Abluxxen",
  "Speed Cups",
  "Bohnanza - Das Duell",
  "Biberbande",
  "Wizard Extreme",
  "Die drei ??? Agenten-Brille",
  "Dobble",
  "Dino-Ei Ausgrabung",
  "Witches",
  "Harry Potter Notizbuch A6 Gleis 9 3/4",
  "Tempo, kleines Faultier!",
  "Halli Galli Junior",
  "Saboteur",
  "Velonimo (Spiel)",
  "Erwischt! (Kartenspiel)",
  "Heckmeck am Bratwurmeck",
  "Geistesblitz",
  "Cabo",
];
