import React, { Component } from "react";
import { ReactComponent as SnowEnd } from "../../assets/SVG/Background/End.svg";
import { ReactComponent as BBDOGroup } from "../../assets/SVG/BBDO_Logo.svg";
import { ReactComponent as BBDO } from "../../assets/SVG/BBDO_logo_RGB.svg";
import { ReactComponent as Batten } from "../../assets/SVG/batten.svg";
import { ReactComponent as Craftwork } from "../../assets/SVG/craftwork.svg";
import { ReactComponent as Interone } from "../../assets/SVG/interone.svg";
import { ReactComponent as Proximity } from "../../assets/SVG/proximity.svg";
import { ReactComponent as ProximityTechnology } from "../../assets/SVG/proximity-technology.svg";
import { ReactComponent as Psg } from "../../assets/SVG/psg.svg";
import Tree from "./elements/Tree";
import $ from "jquery";

import { withRouter } from "react-router-dom";
// !Assets
import Background from "../../assets/PNG/Background/Gradient.png";
class Snow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
    this.myRef = React.createRef();
  }
  resizeSnow() {
    $(document).ready(function () {
      var treeH = $(".tree").height();
      var heightSnow =
        $("#intro-snow").height() + parseInt($("#intro-snow").css("bottom"));

      if (treeH) {
        heightSnow = heightSnow + treeH;
      }
      $("#bbdo").css({
        height: $("#intro-snow").height() / 2,
      });
      $("#snow-end-bottom").css({ height: heightSnow });
      window.addEventListener("resize", () => {
        var treeH = $(".tree").height();
        var heightSnow =
          $("#intro-snow").height() + parseInt($("#intro-snow").css("bottom"));

        if (treeH) {
          heightSnow = heightSnow + treeH;
        }
        $("#snow-end-bottom").css({ height: heightSnow });
        $("#bbdo").css({ height: $("#intro-snow").height() / 2 });
      });
    });
  }
  render() {
    this.resizeSnow();
    let clase =
      this.props.location.pathname !== "/" &&
      this.props.location.pathname !== "/story" &&
      this.props.location.pathname !== "/wuenschen" &&
      this.props.location.pathname !== "/detail" &&
      this.props.location.pathname !== "/thankyou"
        ? "normal"
        : "";
    return (
      <div className="footer">
        <div id="snow-end-bottom" className={clase} ref={this.myRef}>
          <SnowEnd id="intro-snow" />
          <div id="bbdo"></div>
          <Tree />
        </div>
        <div className="logos">
          <BBDOGroup />
          <Batten />
          <BBDO />
          {/* <Craftwork style={{width: 'auto', height: '4rem'}} /> */}
          <Interone />
          <Psg />
          <ProximityTechnology style={{ width: "auto", height: "4rem" }} />
          {/* <Proximity /> */}
        </div>
      </div>
    );
  }
}

export default withRouter(Snow);
