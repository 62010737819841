import React from "react"

import { Link, withRouter } from "react-router-dom";
import Button from './Button';
import { ReactComponent as X } from "../../../assets/SVG/Icons/22px/Close.svg"

const Hilfe = (props) => {
    return (
        <div id="hilfe">
            {props.location.pathname !== '/thankyou' &&
            (
                props.location.pathname !== '/datenschutz' ?
                <Button className="hilfe-cta">
                {
                    // props.location.pathname === '/overlay' ? <h3 className="x" onClick={props.history.goBack.bind(this)}>&times;</h3> : <Link to="/overlay"><h3>Hilfe</h3></Link>
                    props.location.pathname === '/overlay' ? <div className="x"><X  onClick={props.history.goBack.bind(this)}>&times;</X> </div>: <Link to="/overlay"><h3>Hilfe</h3></Link>
                }
                </Button>
                :
                <Button className="hilfe-cta"><div className="x"><X  onClick={props.history.goBack.bind(this)}>&times;</X></div></Button>
                // null
            )}
        </div>
    )
}
 
export default withRouter(Hilfe);