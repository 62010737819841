import React from "react";
import GreenBG from "../assets/PNG/Background/Green-Small.png";
import Regalos from "../assets/PNG/Image/ThankYou.png";
import { Redirect, withRouter } from "react-router-dom";

const ThankYou = (props) => {
  return (
    // return props.history.action !== "POP" ? (
    <div className="column">
      <div id="main-thanks" className="main-thanks">
        <section className="share-go-back">
          <div className="daten-head">
            <h2>
              Vielen Dank! Gemeinsam konnten wir alle 600 Wünsche erfüllen.{" "}
            </h2>
            <img src={Regalos} alt="" />
            <h2>
              Wir wünschen allen ein frohes Weihnachtsfest und freuen uns auf
              die gemeinsamen Projekte im kommenden Jahr!
            </h2>
          </div>
          <div className="background-section">
            <img src={GreenBG} />
          </div>
        </section>
      </div>
    </div>
  );
  // : (
  //   <Redirect to="/" />
  // );
};

export default withRouter(ThankYou);
