import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import GreenBG from "../assets/PNG/Background/Green-Small.png";

// !Assets
import { ReactComponent as Button } from "../assets/SVG/CTA/Big-ml-2.svg";
import { ReactComponent as Arrow } from "../assets/SVG/Icon/right-arrow.svg";

//* Actions
import {
  get_token,
  get_students,
  select_student,
} from "../redux/actions/index";

const colors = ["#f08686", "#d43938", "#91a2ca"];
let count = 0;
class Wuenschen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: 0,
      end: 3,
      redirect: false,
      sorted: false,
      studentsFiltered: [],
    };
  }

  componentDidMount() {
    if (this.props.token == "") {
      this.props.get_token();
    }

    this.students();

    window.scrollTo(0, 0);
  }

  students = async () => {
    try {
      this.props.get_students();

      setInterval(() => {
        this.props.get_students();
      }, 3000);
    } catch (e) {
      // console.log(e);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.studentsFiltered.length > 0) {
      if (
        prevProps.studentsFiltered.length != this.props.studentsFiltered.length
      ) {
        if (
          prevProps.studentsFiltered.length < this.props.studentsFiltered.length
        ) {
          // add
          var idsProps = new Set(this.state.studentsFiltered.map((d) => d.id));

          this.setState({
            studentsFiltered: this.state.studentsFiltered,
          });
        } else {
          // substract
          var idsProps = new Set(this.props.studentsFiltered.map((d) => d.id));
          this.setState({
            studentsFiltered: this.state.studentsFiltered.filter((d) =>
              idsProps.has(d.id)
            ),
          });
        }
      }
      if (
        prevProps.studentsFiltered !== this.props.studentsFiltered &&
        !this.state.sorted
      ) {
        this.setState({
          sorted: true,
          studentsFiltered: this.props.studentsFiltered.sort(
            () => Math.random() - 0.5
          ),
        });
      }
    }
  }

  onClickHandlerMoreStudents = () => {
    const { end } = this.state;
    let newEnd = end + 3;
    this.setState({
      end: newEnd,
    });
  };

  onClickHandler = (wuensch, target) => {
    let bgColor = getComputedStyle(target.currentTarget).backgroundColor;

    this.props.select_student(wuensch.id, bgColor);
    this.setState({
      redirect: wuensch,
    });
  };

  renderRedirect = () => {
    const { redirect } = this.state;
    if (redirect && this.props.students.selected_student !== "") {
      return <Redirect push to="/detail" />;
    }
  };

  resize() {
    $(document).ready(function () {
      if ($(".third-section").length > 0) {
        var treeH = $(".third-section").offset().top;
        var documentH =
          $("#main-daten").outerHeight() - $(".third-section").outerHeight();
        var padding = documentH - treeH;
        $(".third-section").css({ "padding-bottom": padding });

        window.addEventListener("resize", () => {
          // $('.third-section').css({ 'padding-bottom': 0 })

          var treeH = $(".third-section").offset().top;
          var documentH =
            $("#main-daten").outerHeight() - $(".third-section").outerHeight();

          var padding = documentH - treeH;
          $(".third-section").css({ "padding-bottom": padding });
        });
      }

      // if ($('#main-wuenschen').length > 0) {
      //     $('#main-wuenschen').css({'min-height' : $('#main-daten').height()})
      // }
    });
  }

  render() {
    const { students, studentsFilteredBezahlt } = this.props;
    const { start, end, studentsFiltered, sorted } = this.state;

    return <Redirect to="/thankyou" />;
    return students.length > 0 ? (
      <div id="main-wuenschen" className="main-wuenschen">
        {this.renderRedirect()}
        <div className="daten-head">
          {/* <h2>Es sind noch {studentsFiltered.length} von {students.length} Wünschen übrig:</h2> */}
          <h2>
            Einfach auf den Wunschzettel eines Kindes klicken, um die
            individuellen Wünsche zu sehen. Es sind noch{" "}
            {studentsFilteredBezahlt.length} von {students.length} Wünschen
            übrig.
          </h2>
        </div>
        {studentsFiltered.length == 0 && (
          <div className="daten-head">
            <h2>Die Wünsche laden ...</h2>
          </div>
        )}
        <section className="wuenschen-list">
          {studentsFiltered.slice(start, end).map((wuensch, key) => (
            <div
              className="wuensch-container"
              key={key}
              onClick={(target) => this.onClickHandler(wuensch, target)}
            >
              <div className="wuensch-name">
                <h2>
                  {wuensch.student} <br></br>wünscht sich ...
                </h2>
                {/* {wuensch.schule == "GGS Flurstraße" || wuensch.schule == "GGS Sonnenstrasse"?<p>{"Klasse " + wuensch.klasse}</p>:<p>{"SOS Kinderdorf " + wuensch.klasse}</p>} */}
              </div>
              <div className="pfeile">
                <Arrow />
              </div>
            </div>
          ))}
        </section>
        <section className="story-section third-section">
          <Button
            style={{ cursor: "pointer" }}
            onClick={this.onClickHandlerMoreStudents}
          ></Button>
          <div id="about-layout-links">
            <Link to="/story">
              <h3>Mehr zur Aktion</h3>
            </Link>
            <a
              href="https://bbdo.de/de/imprint"
              target="_blank"
              rel="noreferrer"
            >
              <h3>Impressum</h3>
            </a>
            <Link to="/datenschutz">
              <h3>Datenschutz</h3>
            </Link>
          </div>
          <div className="background-section">
            <img src={GreenBG} />
          </div>
        </section>
      </div>
    ) : (
      <div id="main-wuenschen" className="main-wuenschen">
        <div className="daten-head">
          <h2>Die Wünsche laden ...</h2>
        </div>
        {/* <Redirect to="/thankyou" /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    students: state.students.students,
    // studentsFiltered: state.students.students.filter(student => student.bezahlt !== "1" && student.active !== "1").sort(() => Math.random() - 0.5),
    studentsFiltered: state.students.students.filter(
      (student) => student.bezahlt == 0 && student.active == 0
    ),
    studentsFilteredBezahlt: state.students.students.filter(
      (student) => student.bezahlt !== "1"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_token: () => dispatch(get_token()),
    get_students: (token) => dispatch(get_students(token)),
    select_student: (id, color) => dispatch(select_student(id, color)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wuenschen);
