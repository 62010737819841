import { GET_TOKEN } from "../constants/action-types";

const initialState = {
    token: ""
}

function token(state = initialState, action) {
    switch (action.type) {
        case GET_TOKEN:
            return { token: action.token }
        default:
            return state;
    }
}

export default token;