// import Logo from '../../assets/PNG/Logo.png'
// import Snow from '../../assets/PNG/Background/Snow.png'
import { ReactComponent as LogoSVG } from "../../assets/SVG/Logo.svg";
import Header from "../layouts/Header";

import Login from "./Login";

const Intro = () => {
  return (
    <div id="intro">
      <Header />
      <div className="daten-head">
        <h3>
          Wir möchten gemeinsam mit Ihnen und Euch Kinderwünsche erfüllen. Los
          geht’s in drei einfachen Schritten!
        </h3>
      </div>
      <Login />
    </div>
  );
};

export default Intro;
