import React, { Component } from "react"
import { connect } from "react-redux"
import { get_datenschutz } from "../redux/actions/index"

class Datenschutz extends Component {


    componentDidMount() {
        this.props.get_datenschutz()
    }
    render()
    {
        return this.props.datenschutz_content ? (
            <div id="datenschutz-main" className="datenschutz-main">  
                <p dangerouslySetInnerHTML={{ __html: this.props.datenschutz_content }}></p>
            </div>
        ): ( <h3>Loading...</h3> )
    }
}

const mapStateToProps = (state) => {
    return {
        datenschutz_content: state.datenschutz.datenschutz_content
    }
}

const mapDispatchToProps = (dispatch) => {

  return {
    get_datenschutz: () => dispatch(get_datenschutz())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Datenschutz);