import { CHECK_PASSWD } from "../constants/action-types";
import Cookies from 'js-cookie'

const initialState = {
    password_to_check: "V3VlbnNjaGVCQkRPMjAyMCE=",
    password_validate: Cookies.get("validated") !== "true" || typeof( Cookies.get("validated") ) === 'undefined' ? false : true
}

function check_passwd(state = initialState, action) {
    if (action.type === CHECK_PASSWD) {
        
        return {
            ...state,
            password_validate: action.validate
        }
    } 
        
    return state;
    
}

export default check_passwd;