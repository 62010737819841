
import Snow from "../../assets/PNG/Background/Snow-alone.png";

const initialState = {
    params:{
	    "particles": {
	        "number": {
	            "value": 16,
	            "density": {
	                "enable": false
	            }
            },
            
            "shape": {
                // "options": {
                //     "polygon": [
                //     {
                //         "nb_sides": 4,
                //         "particles": {
                //         "opacity": {
                //             "value": 0.8,
                //             "random": {
                //             "enable": true,
                //             "minimumValue": 0.5
                //             }
                //         },
                //         "size": {
                //             "value": 12,
                //             "random": {
                //             "enable": true,
                //             "minimumValue": 2
                //             }
                //         },
                //         "color": {
                //             "value": "#fff"
                //         }
                //         }
                //     }
                    
                //     ],
                //     "star": []
                // },
                // "type": "polygon"
                "type":"circle"
            },
            "size": {
                "value": 12,
                "random": {
                "enable": true,
                "minimumValue": 2
                }
            },
            "move": {
	            "direction": "bottom",
	            "out_mode": "out"
	        },
	        "line_linked": {
	            "enable": false
            },
            
	    },
	    "interactivity": {
	        "modes": {
	            "remove": {
	                "particles_nb": 100
	            }
	        }
	    }
    },
}

function getParticlesParams(state = initialState, action) {
    return state
}

export default getParticlesParams;