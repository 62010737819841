import { GET_STUDENTS, SELECT_STUDENT } from "../constants/action-types";

const initialState = {
    students: [],
    selected_student_id: "",
    selected_color: ""
}

function students(state = initialState, action) {
    switch (action.type) {
        case GET_STUDENTS:
            return {
                ...state,
                students: action.students
            }
        case SELECT_STUDENT:
            return {
                ...state,
                selected_student_id: action.id,
                selected_color: action.color
            }
        default:
            return state
    }
}

export default students;